import React from "react";

import Layout from "../containers/global/layout";
import Banner from "../containers/Banner";
import BreadCrumb from "../containers/BreadCrumb";
import Container from "../utils/Container";
import BlogEntity from "../components/BlogEntity";
import Pagination from "../components/Pagination";

import LinkedIn from "../images/linkedin.png";
import HubspotForm from "../components/HubspotForm";
import PostByTopic from "../components/PostByTopic";
import RecentPost from "../components/RecentPost";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

const Blog = ({ data, pageContext }) => {
  const allBlogs = data.allBlogs.nodes;

  const allTopics = data.allTopics.nodes;
  const topicsList = [];
  allTopics.forEach((topic) => {
    topicsList.push({
      topic: topic.topicName,
      count: topic.blog.length,
      link: `/category/${topic.slug}`,
    });
  });

  const recentPosts = data.recentPosts.nodes;
  const recentPostsList = [];
  recentPosts.forEach((recentPost) => {
    recentPostsList.push({
      image: recentPost.coverImage.gatsbyImageData,
      title: recentPost.title,
      link: `/blog/${recentPost.slug}`,
    });
  });

  const bannerImages = data.bannerImages.nodes;
  return (
    <Layout>
      <Banner
        bgImg={bannerImages.find((bannerImage) => bannerImage.title === "Light House")?.gatsbyImageData}
        mobImg={bannerImages.find((bannerImage) => bannerImage.title === "Light House Mob")?.gatsbyImageData}
      >
        <h3 className="absolute top-2 left-1/2 -translate-x-1/2 md:left-[10%] md:translate-x-0 md:top-14 text-3xl font-extrabold md:text-4xl lg:text-5xl lg:top-1/2 lg:-translate-y-1/2">
          blogs
        </h3>
      </Banner>
      <Container>
        <BreadCrumb
          className="mb-7"
          breadCrumbItems={[
            {
              text: "home",
              link: "/",
            },
            {
              text: "resources",
              link: "/resources",
            },
            {
              text: "blogs",
            },
          ]}
        ></BreadCrumb>
        <section className="flex flex-col gap-5 md:flex-row">
          <section className="md:w-[65%]">
            <section className="flex flex-col gap-4">
              {allBlogs.map((blog) => (
                <BlogEntity
                  key={blog.id}
                  image={blog.coverImage.gatsbyImageData}
                  author={blog.author}
                  title={blog.title}
                  body={blog.body}
                  createdAt={blog.createdAt}
                  link={`/blog/${blog.slug}`}
                ></BlogEntity>
              ))}
            </section>
            {(pageContext.numPages > 1 && (
              <div className="flex items-center">
                <Pagination
                  totalPages={pageContext.numPages}
                  currentPage={pageContext.currentPage}
                  initialPage={"/resources/blog"}
                  paginationUrlAppendString="page"
                  className="mx-auto mt-3"
                ></Pagination>
              </div>
            )) ||
              null}
          </section>
          <section className="md:w-[35%]">
            <section className="mb-5 md:my-0 md:mb-3">
              <p className="text-[#333E47] font-medium">Follow Us</p>
              <img src={LinkedIn} className="w-[5%]"></img>
              <HubspotForm
                portalId="1750400"
                formId="542ff168-80b1-4e99-9980-42222ba880ca"
                containerId="hbspt-blog-subscribe"
              ></HubspotForm>
            </section>
            <PostByTopic topics={topicsList}></PostByTopic>
            <RecentPost className="mt-4" posts={recentPostsList}></RecentPost>
          </section>
        </section>
      </Container>
    </Layout>
  );
};

Blog.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.shape({
    numPages: PropTypes.number,
    currentPage: PropTypes.number,
  }),
};

export default Blog;

export const query = graphql`
  query BlogListPage($skip: Int!, $limit: Int!) {
    allBlogs: allContentfulBlog(skip: $skip, limit: $limit) {
      nodes {
        author
        title
        slug
        body {
          raw
        }
        createdAt
        id
        coverImage {
          gatsbyImageData
        }
      }
    }

    allTopics: allContentfulBlogTopic {
      nodes {
        topicName
        blog {
          id
        }
        slug
      }
    }

    recentPosts: allContentfulBlog(sort: { fields: createdAt, order: ASC }, limit: 3) {
      nodes {
        coverImage {
          gatsbyImageData
        }
        title
        slug
      }
    }

    bannerImages: allContentfulAsset(filter: { title: { in: ["Light House Mob", "Light House"] } }) {
      nodes {
        title
        gatsbyImageData
      }
    }
  }
`;
