import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";

const BlogEntity = ({ image, title, author, body, createdAt, link }) => {
  const parsedCreatedDate = new Date(Date.parse(createdAt));
  const formattedDateString = parsedCreatedDate.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  const content = JSON.parse(body.raw).content[0].content[0].value;

  return (
    <Link to={link} className="flex flex-col md:flex-row md:pb-1 border-b border-gray-400 gap-4">
      <GatsbyImage imgClassName="blog-cover-image" className="blog-cover-wrapper" image={image} alt="cover" />
      <div className="flex flex-col gap-2 md:w-[60%] xl:w-[70%] xl:pr-7">
        <h3 className="text-primary-blue-dark md:text-sm text-lg font-bold xl:text-xl">{title}</h3>
        <p className="text-sm mb-4 h-[11.25rem] md:h-[2.5rem] xl:h-[3.75rem] text-ellipsis break-words overflow-hidden">
          {content}
        </p>
        <small className="text-gray-500 text-xs">
          by {author} on {formattedDateString}
        </small>
        <Link to={link} className="block text-primary-blue-dark font-bold">
          Read more &gt;
        </Link>
      </div>
    </Link>
  );
};

BlogEntity.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
  author: PropTypes.string,
  createdAt: PropTypes.string,
  link: PropTypes.string,
};

export default BlogEntity;
